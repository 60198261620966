import React from 'react'
import PropTypes from 'prop-types'
import { useRouter } from 'next/router'
import { useState, useEffect } from 'react'

import LoadingProgressBar from 'components/Indicator/LoadingProgressBar'

type ComponentProps = {}

const RouteLoadingBar = ({}: ComponentProps) => {
  const router = useRouter()

  const [state, setState] = useState({
    isRouteChanging: false,
    loadingKey: 0,
  })

  useEffect(() => {
    const handleRouteChangeStart = () => {
      setState((prevState) => ({
        ...prevState,
        isRouteChanging: true,
        loadingKey: prevState.loadingKey ^ 1,
      }))
    }

    const handleRouteChangeEnd = () => {
      setState((prevState) => ({
        ...prevState,
        isRouteChanging: false,
      }))
    }

    router.events.on('routeChangeStart', handleRouteChangeStart)
    router.events.on('routeChangeComplete', handleRouteChangeEnd)
    router.events.on('routeChangeError', handleRouteChangeEnd)

    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart)
      router.events.off('routeChangeComplete', handleRouteChangeEnd)
      router.events.off('routeChangeError', handleRouteChangeEnd)
    }
  }, [router.events])

  return <LoadingProgressBar isRouteChanging={state.isRouteChanging} key={state.loadingKey} />
}

RouteLoadingBar.propTypes = {
  //
}

export default RouteLoadingBar
