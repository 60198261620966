import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import common_en from 'translations/en/common.json'
import forms_en from 'translations/en/forms.json'
import pages_en from 'translations/en/pages.json'
import boards_en from 'translations/en/boards.json'

i18n.use(initReactI18next).init({
  lng: 'en',
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  resources: {
    en: {
      common: common_en,
      forms: forms_en,
      pages: pages_en,
      boards: boards_en,
    },
  },
})

export default i18n
