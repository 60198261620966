import { library, config } from '@fortawesome/fontawesome-svg-core'

import * as proFad from '@fortawesome/pro-duotone-svg-icons'
import * as proFal from '@fortawesome/pro-light-svg-icons'
import * as proFar from '@fortawesome/pro-regular-svg-icons'
import * as proFas from '@fortawesome/pro-solid-svg-icons'
import * as proThin from '@fortawesome/pro-thin-svg-icons'

import * as brands from '@fortawesome/free-brands-svg-icons'

import * as fas from '@fortawesome/free-solid-svg-icons'

config.autoAddCss = false

// General
library.add(
  proFad.faSpinnerThird,
  proFal.faChevronLeft,
  proFar.faEyes,
  proFas.faFeather,
  proFas.faKey,
  proFas.faSackDollar,
  proFas.faCubes,
  brands.faGithub,
  brands.faDiscord,
  brands.faTelegram,
  brands.faInstagram,
  brands.faTwitter,
  brands.faFacebook,
  brands.faReddit,
  brands.faLinkedin,
  brands.faWhatsappSquare,
  fas.faCloudArrowUp,
  proFar.faGlobe,
  proFal.faTimes,
  proFal.faBars,
  proFar.faArrowUpRightFromSquare,
  proFas.faBuildings,
  proFas.faMessagesQuestion,
  proFar.faPlus,
  proFar.faCog,
  proThin.faHandPointUp,
  proFar.faHandPointUp,
  proThin.faHandPointDown,
  proFar.faHandPointDown,
  proFar.faLoader,
  proFar.faCopy,
  proFar.faBars,
  proFas.faAnchor,
  proFas.faHandshakeSimple,
  proFas.faPeopleGroup,
  proFas.faTimelineArrow,
  proFas.faCheck,
  proFas.faTimes,
  proFar.faImage,
  proFar.faLink,
  proFar.faChevronRight,
  proFar.faKey,
  proFar.faPipe,
  proFas.faArrowLeft,
)
