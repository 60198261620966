import { createContext, useEffect, useState } from 'react'
import { SupabaseClient, Session, User, ApiError } from '@supabase/supabase-js'

type AuthContextType = {
  user: User | null
  session: Session | null
  signOut: () => Promise<{ error: ApiError | null }>
}

export const AuthContext = createContext<AuthContextType>({
  user: null,
  session: null,
  signOut: () =>
    new Promise((resolve) =>
      resolve({
        error: null,
      }),
    ),
})

type AuthProviderType = {
  supabase: SupabaseClient
  children: React.ReactNode
}

export const AuthProvider = ({ supabase, ...props }: AuthProviderType) => {
  const [session, setSession] = useState<Session | null>(null)
  const [user, setUser] = useState<User | null>(null)

  useEffect(() => {
    const activeSession = supabase.auth.session()

    setSession(activeSession)
    setUser(activeSession?.user ?? null)

    const { data: authListener } = supabase.auth.onAuthStateChange((_event, currentSession) => {
      setSession(currentSession)
      setUser(currentSession?.user ?? null)
    })

    return () => {
      authListener?.unsubscribe()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <AuthContext.Provider
      value={{
        session,
        user,
        signOut: () => supabase.auth.signOut(),
      }}
      {...props}
    />
  )
}
