import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { useNProgress } from '@tanem/react-nprogress'

type ComponentProps = {
  isRouteChanging: boolean
}

const LoadingProgressBar = ({ isRouteChanging }: ComponentProps) => {
  const { animationDuration, isFinished, progress } = useNProgress({
    isAnimating: isRouteChanging,
  })

  return (
    <div
      className={classNames('pointer-events-none', {
        'opacity-0': isFinished,
        'opacity-1': isFinished === false,
      })}
      style={{ transition: `opacity ${animationDuration}ms linear` }}
    >
      <div
        className={classNames({
          'bg-gradient-to-r from-fuchsia-500 via-orange-500 to-fuchsia-500': true,
          'h-1 left-0 top-0 fixed w-full': true,
        })}
        style={{
          marginLeft: `${(-1 + progress) * 100}%`,
          transition: `margin-left ${animationDuration}ms linear`,
          zIndex: '1031',
        }}
      >
        <div
          className={classNames({
            'absolute right-0 block h-full opacity-1': true,
          })}
          style={{
            transform: 'rotate(3deg) translate(0px, -4px)',
            width: '100px',
          }}
        />
      </div>
    </div>
  )
}

LoadingProgressBar.propTypes = {
  isRouteChanging: PropTypes.bool,
}

export default LoadingProgressBar
