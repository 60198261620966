// Helping fonts
import '@fontsource/fira-sans/300.css' // font-light  | lead text
import '@fontsource/fira-sans/400.css' // font-normal | inputs
import '@fontsource/fira-sans/500.css' // font-medium | buttons
import '@fontsource/fira-sans/600.css' // font-semibold
import '@fontsource/fira-sans/700.css' // font-bold
import '@fontsource/fira-sans/800.css' // font-extrabold
import '@fontsource/fira-sans/900.css' // font-black

// Base fonts
import '@fontsource/montserrat/300.css' // font-light
import '@fontsource/montserrat/400.css' // font-normal
import '@fontsource/montserrat/500.css' // font-medium
import '@fontsource/montserrat/600.css' // font-semibold
import '@fontsource/montserrat/700.css' // font-bold

// Libraries
import 'react-responsive-modal/styles.css'
import '@szhsin/react-menu/dist/index.css'
import '@szhsin/react-menu/dist/transitions/slide.css'
import '@solana/wallet-adapter-react-ui/styles.css'

// Tailwind. must be after 3rd party libs
// so our classes override 3rd party
import '../styles/globals.css'

// Font Awesome
import '@fortawesome/fontawesome-svg-core/styles.css'
import 'config/fontAwesome'

// Translations
import 'config/i18n'

import type { ReactElement, ReactNode } from 'react'
import type { NextPage } from 'next'
import type { AppProps } from 'next/app'
import { SessionProvider } from 'next-auth/react'
import { AuthProvider } from 'components/Context/Supabase'
import RouteLoadingBar from 'components/Layout/RouteLoadingBar'
import GoogleAnalytics from 'components/Scripts/GoogleAnalytics'
import supabase from 'config/supabase'

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

export default function MyApp({
  Component,
  pageProps: { session, ...pageProps },
}: AppPropsWithLayout) {
  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout ?? ((page) => page)

  return (
    <AuthProvider supabase={supabase}>
      <SessionProvider session={session}>
        {process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID && (
          <GoogleAnalytics trackingId={process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID} />
        )}

        <RouteLoadingBar />
        {getLayout(<Component {...pageProps} />)}
      </SessionProvider>
    </AuthProvider>
  )
}
