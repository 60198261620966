import React from 'react'
import PropTypes from 'prop-types'
import Script from 'next/script'

type ComponentProps = {
  trackingId: string
}

const GoogleAnalytics = ({ trackingId }: ComponentProps) => {
  return (
    <>
      <Script
        src={`https://www.googletagmanager.com/gtag/js?id=${trackingId}`}
        strategy="afterInteractive"
      />

      <Script id="google-analytics" strategy="afterInteractive">
        {`
         window.dataLayer = window.dataLayer || [];
         function gtag(){dataLayer.push(arguments);}
         gtag('js', new Date());
       
         gtag('config', '${trackingId}');
      `}
      </Script>
    </>
  )
}

GoogleAnalytics.propTypes = {
  trackingId: PropTypes.string,
}

export default GoogleAnalytics
